import { useParams } from 'next/navigation';
import {env} from '@/env';

const DEFAULT_OBE_URL = new URL(env.NEXT_PUBLIC_OBE_DIRECT_URL || 'https://bookings.jucy.com/obe/direct/trip/create/');

export const useObeUrl = () => {
    const route = useParams();
    const countryCodeSegment = route?.lang ? `${route.lang}/` : '/';
    return new URL(`/obe/${countryCodeSegment}direct/trip/create/`, DEFAULT_OBE_URL);
};
