'use client';

import dynamic from 'next/dynamic';
import { env } from '@/env';
import { CmsPage } from '@/services/cms/pages/CmsPage';
import { CookieConsent } from './CookieConsent';


const Clarity = dynamic(() => import('./Clarity').then((mod) => mod.Clarity));
const CommissionJunction = dynamic(() => import('./CommissionJunction').then((mod) => mod.CommissionJunction));
const IntercomChatbot = dynamic(() => import('./IntercomChatbot').then((mod) => mod.IntercomChatbot));
const TradeDoubler = dynamic(() => import('./TradeDoubler').then((mod) => mod.TradeDoubler));
const AwinMasterTag = dynamic(() => import('./AwinMasterTag').then((mod) => mod.AwinMasterTag));

export interface AnalyticsProps {
    pageData?: CmsPage;
    pageSlug?: string[];
}

export const Analytics = (props: AnalyticsProps) => (
    <>
        {env.NEXT_PUBLIC_CJ_ENTERPRISE_ID ? <CommissionJunction enterpriseId={env.NEXT_PUBLIC_CJ_ENTERPRISE_ID} {...props} /> : undefined}
        {env.NEXT_PUBLIC_INTERCOM_API_BASE_URL && env.NEXT_PUBLIC_INTERCOM_APP_ID ? (
            <IntercomChatbot
                config={{
                    app_id: env.NEXT_PUBLIC_INTERCOM_APP_ID,
                    api_base: env.NEXT_PUBLIC_INTERCOM_API_BASE_URL,
                }}
            />
        ) : undefined}
        {env.NEXT_PUBLIC_TRADEDOUBLER_ORG_ID ? <TradeDoubler {...props} /> : undefined}
        {env.NEXT_PUBLIC_CLARITY_KEY ? <Clarity clarityKey={env.NEXT_PUBLIC_CLARITY_KEY} /> : undefined}
        <CookieConsent />
        <AwinMasterTag awinId={env.NEXT_PUBLIC_AWIN_ID} />
    </>
);

