'use client';

import React, { useCallback } from 'react';
import { format } from 'date-fns';
import { sleep } from '@jucy-ui/common';
import { SearchForm, SearchFormHiddenFields, SearchFormProps, SearchFormValues, SearchFormVariants, searchFormValuesToData } from '@jucy-ui/components/SearchForm';
import { useSite } from '@/hooks/useSite';
import { jucyAttribution } from '@/lib/jucyAttribution';
import analytics from '@/services/analytics';
import {useObeUrl} from '@/hooks/useObeUrl';

export type WebsiteSearchFromProps = Omit<SearchFormProps, 'onSubmit'> & {
    fleetType?: string;
    fleetCategory?: string;
    layout?: string;
    country?: string;
    location?: string;
    hiddenFields?: SearchFormHiddenFields;
};

const WebsiteSearchForm: React.FC<WebsiteSearchFromProps> = ({ fleetType='campervan', fleetCategory, layout, country, location, hiddenFields }) => {
    const site = useSite();
    const obeUrl = useObeUrl()
    const onSubmit = useCallback(async (data: SearchFormValues) => {
        const { pickUpBranch } = searchFormValuesToData(data);
        analytics.plugins.bookingFunnel.bookingInitiated({
            fleetType: data.fleetType,
            pickUpLocation: data.pickUpBranch,
            pickUpDate: data.pickUpDate,
            dropOffLocation: data.dropOffBranch,
            dropOffDate: data.dropOffDate,
            coupon: data.promoCode,
            brand: 'jucy',
        });
        const countryCodeSegment = pickUpBranch?.countryCode ? `${pickUpBranch?.countryCode}/` : '/';
        const url = new URL(`/obe/${countryCodeSegment}direct/trip/create/${data.fleetType}`, obeUrl);
        url.searchParams.set('pickUpLocation', data.pickUpBranch);
        url.searchParams.set('dropOffLocation', data.dropOffBranch);
        url.searchParams.set('pickUpDate',  format(data.pickUpTime, 'yyyy-MM-dd'));
        url.searchParams.set('dropOffDate',   format(data.dropOffDate, 'yyyy-MM-dd'));
        url.searchParams.set('pickUpTime',format(data.pickUpTime , 'HHmm'));
        url.searchParams.set('dropOffTime',format(data.dropOffTime, 'HHmm'));
        if (data.promoCode) {
            url.searchParams.set('promoCode', data.promoCode);
        }
        url.searchParams.set('action', 'search');
        window.location.href = jucyAttribution.decorateUrl(url.href);
        await sleep(15000);
    }, [obeUrl]);

    const defaultValues = {
        fleetType: fleetType,
        fleetCategory: fleetCategory,
        pickUpBranch: location,
    };
    let region: string | undefined = undefined;
    let variant: SearchFormVariants = 'large';
    if (layout === 'vertical') {
        variant = 'small';
    }
    if (country === 'Australia') {
        region = 'au';
    }
    if (country === 'New Zealand') {
        region = 'nz';
    }
    return (
        <SearchForm
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            mode="all"
            options={{
                variant,
                region,
                sortRegion: site.urlSegment,
                hiddenFields: {
                    fleetType: typeof hiddenFields?.fleetType !== 'undefined' ? hiddenFields?.fleetType : Boolean((fleetType && fleetCategory) || (fleetType && location)),
                    ...hiddenFields,
                },
            }}
        />
    );
};

export default WebsiteSearchForm;
